import React from "react";
import styles from "../../components/Landers/landers.module.scss";

export default ({ info, path, five }) => (
	<section className={styles.unique}>
		<h2>What makes SpringVerify unique?</h2>
		<div className={`${styles.list} ${five ? styles.five : ""}`}>
			{info.map((e, i) => (
				<div key={i}>
					<img
						data-src={`https://assets-sv-in.gumlet.io/LP/${path}/WhatsUnique/${
							i + 1
						}.svg`}
						alt=""
					/>
					<p>{e}</p>
				</div>
			))}
		</div>
	</section>
);
