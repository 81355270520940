import React from "react";
import { Helmet } from "react-helmet";

export default ({ title, desc, url, imgurl }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={desc} />
    <meta property="og:site_name" content="SpringVerify India" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={imgurl} />
    <meta property="og:image:secure_url" content={imgurl} />
    <meta property="og:image:width" content="1280" />
    <meta property="og:image:height" content="640" />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={desc} />
    <meta property="twitter:card" content="website" />
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:image" content={imgurl} />
    <meta property="twitter:site" content="@springroleinc" />
    <link rel="canonical" href={url}></link>
  </Helmet>
);
