import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "../../components/Landers/landers.module.scss";

export default ({ testimonials, path, h2 }) => {
  const [showArrow, setShowArrow] = useState(true);

  let settings = {
    autoplay: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 5000,
    dots: false,
    prevArrow: showArrow && (
      <img
        data-src="https://assets-sv-in.gumlet.io/LP/arrow.svg"
        className={`${showArrow ? "" : "hidden"}`}
      />
    ),
    nextArrow: showArrow && (
      <img
        data-src="https://assets-sv-in.gumlet.io/LP/arrow.svg"
        className={`${showArrow ? "" : "hidden"}`}
      />
    ),
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={styles.testimonials}>
      <h2>{h2 ? h2 : "Here’s what our customers have to say about us 🎉"}</h2>
      <Slider
        {...settings}
        beforeChange={() => {
          setShowArrow(false);
        }}
        afterChange={() => {
          setShowArrow(true);
        }}
      >
        {testimonials.map(({ p, name, company }, i) => (
          <div key={i}>
            <img
              className={styles.quote}
              data-src="https://assets-sv-in.gumlet.io/LP/quote.svg"
            />
            <p>{p}</p>
            <img
              key={i}
              className={styles.logo}
              data-src={`https://assets-sv-in.gumlet.io/LP/Employment/Testimonials/${company
                .split(" ", 1)[0]
                .toLowerCase()}.svg`}
              alt=""
            />
            <div className={styles.name}>{name}</div>
            <div className={styles.company}>{company}</div>
          </div>
        ))}
      </Slider>
    </section>
  );
};
