import React from "react";
import { MainCTAs } from "./Banner";
import styles from "./landers.module.scss";
import SectionTemplate from "./SectionTemplate";

export default ({ img, h3, p }) => {
  return (
    <div className={styles.bg}>
      <SectionTemplate img={img} imgTop h3={h3} p={p}>
        <MainCTAs cta="Get Started" />
      </SectionTemplate>
    </div>
  );
};
