import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import Info from "../../../components/Landers/Info";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";

const steps = [
  {
    h5: "Get your candidate’s social profile details",
    p:
      "Request candidates to provide their social media \nhandles to initiate the background check.",
  },
  {
    h5: "Scan candidates profiles",
    p:
      "A thorough scanning is done on all social media \nprofiles to find any notable discrepancies.",
  },
  {
    h5: "Screen the right candidate",
    p:
      "In case of any discrepancy or flags such as political rant, illegal conduct etc. you’ll be notified of it instantly. Ensure you get a comprehensive view of your candidate’s social presence before you hire them.",
  },
];

const testimonials = [
  {
    name: "Mariam M",
    company: "Touchkin eServices Pvt. Ltd.",
    p: `It's the first time we are doing background checks. 
    But still, SpringVerify gave us very good clarity on the process. 
    They helped us in setting up the account quickly and and helped us perform checks without any hassle.`,
  },
  {
    name: "Purushottam N ",
    company: "Speegile Consulting",
    p: `No doubt SpringVerify has transformed the way we run background checks. 
    I love the variety of checks it offers that delivers accurate results in a quick and efficient manner. `,
  },
  {
    name: "Manvi K ",
    company: "Inshorts",
    p: `One thing that really stands-out is their GREAT customer service. The Dedicated Account Manager 
    responds in a very timely manner to any question at the drop of a hat and you really can't ask for more than that!`,
  },
];

export default () => (
  <div className={styles.SocialMedia}>
    <SEO
      title="Social Media Verification Service | SpringVerify India"
      desc="Confirm your candidate's social media presence using SpringVerify to mitigate the risk of wrong hires."
      url="https://in.springverify.com/screenings/social-media-verification/"
      imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(social+media+verification).jpg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={
          "Get a Holistic View of \nyour Candidate with \nSocial Media Verifications"
        }
        p={
          "Screen your candidate’s social media behaviour to ensure you \nhire the right fit for your company."
        }
        img="https://assets-sv-in.gumlet.io/LP/Social/banner.png"
      />
    </div>
    <Brands />
    <section>
      <SectionTemplate
        img="Social/need.svg"
        h2="Significance of \nSocial Media Verifications"
        p={`Since an employee represents a company’s interest - their online presence can have a direct impact on your company reputation and values. Hence verifying your candidate’s social media presence can help you to:`}
        ul={[
          "Hire qualified candidates",
          "Ensure you don’t hire candidates with negative social presence",
          "Build trust, transparency and security amongst your people",
        ]}
      />
    </section>
    <Info
      h2="Information you will get through\nSocial Media Verifications"
      path="Social"
      info={[
        "Violent political rants or \nillegal conduct",
        "Crimes such as supporting \nonline extremist communities",
        "Any racist, sexist \nbehaviour or abuses",
        "Promotion of illegal drugs \ndirectly or indirectly",
      ]}
    />
    <ThreeSteps
      steps={steps}
      candidate={"https://assets-sv-in.gumlet.io/LP/Social/candidate.png"}
      path="Social"
    />
    <section>
      <SectionTemplate
        img="Social/exp.svg"
        h2={"Guaranteed Seamless Experience \n“The SpringVerify way”"}
        p={`Right from running instant checks to providing documented reports, SpringVerify does it all with the best turnaround times. Here’s why SpringVerify provides the best experience,`}
        ul={[
          "Paperless onboarding. No form filling & manual intervention",
          "Run checks anytime, anywhere with real time results using digital verification",
          "Integrate your ATS/ HR system seamlessly with SpringVerify’s robust API",
          "Mobile optimized dashboard to track status of the background check",
          "Complete workflow automation enhancing productivity",
        ]}
      />
    </section>
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="Social/get-started.svg"
      h3="Get started with\nSocial Media Screening"
      p="Screen your candidate’s profile instantly with an industry leader in background verification."
    />
    <Footer />
  </div>
);
