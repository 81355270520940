import React from "react";
import styles from "../../components/Landers/landers.module.scss";

export default ({ list }) => {
  const arr = list || [
    "indorama",
    "inshorts",
    "rently",
    "airmeet",
    "simpl",
    "civica",
  ];
  return (
    <section className={styles.brands}>
      <h2>Top brands that trust SpringVerify</h2>
      <div className={styles.list}>
        {arr.map((e, i) => (
          <img
            key={i}
            data-src={`https://assets-sv-in.gumlet.io/LP/Brands/${e}.svg`}
            alt={e}
          />
        ))}
      </div>
    </section>
  );
};
