import { useEffect, useState } from "react";

const IsMobile = () => {
	const [width, setWidth] = useState(
		typeof window !== `undefined` && window.innerWidth
	);
	const handleWindowSizeChange = () => {
		setWidth(typeof window !== `undefined` && window.innerWidth);
	};

	useEffect(() => {
		typeof window !== `undefined` &&
			window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			typeof window !== `undefined` &&
				window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	return width <= 768;
};

export default IsMobile;
