import React from "react";
import styles from "../../components/Landers/landers.module.scss";
import { br } from "./SectionTemplate";

export default ({ h2, info, path }) => (
  <div className={styles.info}>
    <section>
      <h2>{br(h2)}</h2>
      <div className={styles.list}>
        {info.map((e, i) => (
          <div>
            <img
              key={i}
              data-src={`https://assets-sv-in.gumlet.io/LP/${path}/Info/${
                i + 1
              }.svg`}
              alt="info"
            />
            <p>{e}</p>
          </div>
        ))}
      </div>
    </section>
  </div>
);
