import React from "react";
import styles from "./landers.module.scss";
import isMobile from "./IsMobile";

export const MainCTAs = ({ cta = "Contact us" }) => (
  <div className={styles.mainCTAs}>
    <a href="/contact-us/" className={styles.primary}>
      {cta}
    </a>
  </div>
);

const Banner = ({ h1, p, a, img }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.bannerLeft}>
        <h1>{h1} </h1>
        <p>{p}</p>
        <div className={styles.BannerCTAWrap}>
          <MainCTAs />
          <a
            className={styles.APIDoc}
            href="https://docs-bgv.springverify.com/#add-education"
          >
            {a}
          </a>
        </div>
      </div>
      <div className={styles.bannerRight}>
        {!isMobile() && <img data-src={img} alt="banner" />}
      </div>
    </div>
  );
};

export default Banner;
