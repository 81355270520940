import React from "react";
import styles from "./landers.module.scss";
import cn from "classnames";
import IsMobile from "./IsMobile";

export const br = (el) =>
  el.split("\\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {IsMobile() ? " " : <br />}
    </React.Fragment>
  ));

export default ({ img, imgTop, h2, h3, p, reversed, ul, tag, children }) => (
  <div className={cn(styles.flex, reversed && styles.reversed)}>
    {(!IsMobile() || imgTop) && (
      <img data-src={"https://assets-sv-in.gumlet.io/LP/" + img} />
    )}
    <div>
      {tag && <div className={styles.tag}>{tag}</div>}
      {h2 && <h2>{br(h2)}</h2>}
      {h3 && <h3>{br(h3)}</h3>}
      {IsMobile() && !imgTop && (
        <img data-src={"https://assets-sv-in.gumlet.io/LP/" + img} />
      )}
      {p && <p>{p}</p>}
      {ul && (
        <ul className={styles.ul}>
          {ul && ul.map((li, i) => <li key={i}>{li}</li>)}
        </ul>
      )}
      {children}
    </div>
  </div>
);
